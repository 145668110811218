.container {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.active {
  color: var(--content-blue);
}

.page {
  font-weight: 600;
  font-size: 14px;
  transition: all .15s ease;

  &:hover {
    color: var(--content-blue);
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    background-color: var(--background-blue);
    border-color: var(--border-blue);

    svg path {
      fill: var(--content-white);
    }
  }

  svg path {
    fill: var(--content-blue);
  }
}
.section {
  margin-bottom: 80px;
  padding: 24px 0;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  padding: 8px 16px;
  font-weight: 600;
  font-size: 20px;
  background-color: var(--background-white);
  color: #000C74;
  border-radius: var(--radius-4);
  cursor: pointer;

  &Active {
    background-color: var(--background-blue);
    color: var(--content-white);
  }
}

.btn {
  margin-top: 50px;
  text-align: center;
}
.wrapper {
  display: inline-block;
  padding: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  border-radius: var(--radius-8);
  user-select: none;

  &--variant {
    &-green {
      color: #32BC6C;
      background-color: #EBF8F0;
    }

    &-orange {
      color: #FF7A00;
      background-color: #FFECDA;
    }

    &-red {
      color: var(--status-red);
      background-color: var(--status-red-light);
    }

    &-blue {
      color: var(--status-blue);
      background-color: var(--status-blue-light);
    }
  }
}

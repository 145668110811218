.wrapper {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid transparent;
  border-radius: 32px;
  cursor: pointer;
  transition: all .3s ease;

  &--size {
    &-sm {
      padding: 4px 32px;
    }

    &-md {
      padding: 8px 24px;
    }

    &-lg {
      padding: 16px 64px;
    }
  }

  &--radius {
    &-sm {
      border-radius: 12px;
    }

    &-md {
      border-radius: 32px;
    }

    &-lg {
      border-radius: 48px;
    }
  }

  &--background {
    &-transparent {
      background-color: transparent;
    }

    &-white {
      background-color: var(--background-white);
    }

    &-danger {
      background-color: var(--background-danger);
    }

    &-blue {
      background-color: var(--background-blue);
    }
  }

  &--color {
    &-white {
      color: var(--content-white);
    }

    &-danger {
      color: var(--content-danger);
    }
  }

  &--border {
    &-white {
      border-color: var(--border-white);
    }

    &-danger {
      border-color: var(--border-danger);

      &:hover {
        background-color: var(--background-danger);
        color: var(--content-white);
      }
    }

    &-blue {
      border-color: var(--border-blue);
    }
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      opacity: .9;
    }
  }
}
.content {
  position: relative;
  padding: 40px 34px 150px;
  background-color: var(--background-blue);
  border: 1px solid rgba(110, 91, 255, 0.15);
  box-shadow: 0 12px 26px rgba(110, 91, 255, 0.36);
  border-radius: var(--radius-10);
}

.flex {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 75%;
}

.right {
  text-align: right;
}

.title {
  display: block;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
}

.largeTitle {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: var(--content-white);
}

.smallTitle {
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.personal {
  display: inline-block;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  background-color: #FF7A00;
  color: #ffffff;
  border-radius: var(--radius-8);

  &.active {
    background-color: #32BC6C;
  }
}

.center {
  transform: translateY(-100px);
  width: calc(100% - 100px);
  margin: 0 auto;
}

.card {
  height: 100%;
  padding: 24px;
  background-color: var(--background-white);
  box-shadow: 0 4px 16px rgba(4, 22, 116, 0.26);
  border-radius: var(--radius-10);

  &Content {
    display: flex;
    column-gap: 32px;
  }

  &Icon {
    flex-shrink: 0;
  }

  &Key {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: rgba(0, 12, 116, 0.6);
  }

  &Value {
    display: block;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 12, 116, 0.6);
  }

  &Degrees {
    display: flex;
    column-gap: 5px;

    li {
      padding: 4px 8px;
      font-size: 11px;
      line-height: 13px;
      color: rgba(0, 12, 116, 0.6);
      border-radius: var(--radius-20);
      border: 1px solid;
    }
  }

  &Line {
    background-color: var(--background-danger);
    border-radius: var(--radius-8);
  }

  &Percentage {
    width: var(--percentage);
    height: 6px;
    background: var(--background-blue);
    border-radius: var(--radius-8);
  }

  &Count {
    &Key {
      display: block;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: rgba(0, 12, 116, 0.6);
    }

    &Value {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000C74;
    }

    &Before {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #6E5BFF;
      border-radius: var(--radius-8);
    }
  }

  .red {
    background-color: var(--background-danger);
  }
}

.addition {
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 24px;

  &Title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6E5BFF;
  }

  &Button {
    display: flex;
    justify-content: flex-end;
  }
}

.works {
  padding-left: 20px;

  &Title {
    margin-bottom: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #000C74;
  }

  li {
    font-size: 16px;
    line-height: 32px;
    color: #000C74;
  }
}
html {
  vertical-align: baseline;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5FB;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  outline: none;
}

img {
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 0;
  outline: none;
}

form {
  width: 100%;
}

input,
textarea,
select {
  outline: none;
}
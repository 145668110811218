.wrapper {
  &--state {
    &-default {
      :global(.ant-select-selector) {
        border-color: var(--border-default);

        &:hover {
          border-color: var(--border-primary);
        }
      }
    }

    &-success {
      :global(.ant-select-selector) {
        border-color: var(--border-primary);

        &:hover {
          border-color: var(--border-primary);
        }
      }
    }

    &-error {
      :global(.ant-select-selector) {
        border-color: var(--border-danger) !important;
        box-shadow: 0 0 0 2px rgba(238, 149, 149, 0.1);


        &:hover {
          border-color: var(--border-danger);
        }
      }
    }
  }
}

.select {
  width: 100%;
}

.message {
  color: var(--content-danger);
}
@mixin theme-light {
  --background-white: #ffffff;
  --background-danger: #ff007a;
  --background-blue: #6E5BFF;
  --background-loading-full: rgba(255, 255, 255, 0.5);

  --content-white: #ffffff;
  --content-danger: #ff007a;
  --content-blue: #6E5BFF;

  --border-white: #ffffff;
  --border-danger: #ff007a;
  --border-blue: #6E5BFF;

  --radius-2: 2px;
  --radius-4: 4px;
  --radius-8: 8px;
  --radius-10: 10px;
  --radius-15: 15px;
  --radius-20: 20px;
}

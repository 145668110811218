.section {
  margin-bottom: 80px;
  padding: 24px 0;
}

.content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 120px;
  background-color: rgba(115, 115, 255, 0.6);
  border-radius: 30px;
}

.description {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--content-white);
}

.chevron {
  transition: all .3s linear;
  transform: rotate(-90deg);

  &-active {
    transform: rotate(0);
  }
}
.wrapper {
  border-radius: var(--radius-10);
  border: 1px solid rgba(110, 91, 255, 0.30);
  overflow: hidden;
  transition: all .3s ease;

  &--open {
    border-color: #4D35FF;

    .headName {
      color: var(--content-blue);
    }
  }
}

.head {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 20px;
  cursor: pointer;

  &Name {
    margin-right: auto;
    font-weight: 500;
    font-size: 18px;
    color: #010D74;
  }

  &Icon {
    color: var(--content-blue);
    cursor: pointer;
  }
}

.body {
  padding: 20px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &Key {
    margin-bottom: 8px;
    font-size: 14px;
    color: rgba(0, 12, 116, 0.60);
  }

  &Value {
    font-size: 16px;
    line-height: 24px;
    color: #000C74;
  }
}
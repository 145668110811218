.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2001;
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 24px rgba(4, 22, 116, 0.08);

  &-static {
    position: static;
    background: linear-gradient(63.56deg, #6B50FF 48.13%, #A145FF 100%), rgba(255, 255, 255, 0.05);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--content-white);
  }
}

.line {
  width: 2px;
  height: 30px;
  display: block;
  background-color: rgba(255, 255, 255, 0.1);
}

.siteName {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.test {
  font-weight: 700;
  color: var(--content-danger);
}

.enter {
  padding: 10px 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.01em;
  color: var(--content-white);
  border: 1px solid var(--border-white);
  border-radius: 32px;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background-color: var(--background-danger);
    color: var(--content-white);
    border-color: var(--border-danger);
  }
}
.section {
  position: relative;
  margin-bottom: 80px;
  padding: 180px 0 150px;
  background-image: url("../../../../assets/images/hero_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--content-white);
}

.content {
  text-align: center;
}

.title {
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 56px;
  line-height: 100%;
  letter-spacing: -0.01em;
}

.description {
  max-width: 1100px;
  margin: 0 auto 60px;
  font-weight: 500;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: 0.01em;
}

.search {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1140px;
  width: 100%;

  .icon {
    cursor: pointer;

    &:hover svg {
      path {
        fill: var(--content-blue);
      }
    }
  }

  input {
    padding: 20px 80px 20px 40px;
    box-shadow: 0 3px 72px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    transition: all .15s ease;
  }
}

.enter {
  padding: 15px 45px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.01em;
  background-color: var(--background-danger);
  color: var(--content-white);
  border-color: var(--border-danger);
  border-radius: 32px;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background-color: var(--background-white);
    color: var(--content-danger);
    border-color: var(--border-white);
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &Text {
    display: inline-block;
    margin-right: 15px;
    font-weight: 600;
    font-size: 18px;
  }

  &Count {
    display: inline-block;
    margin-right: 30px;
    font-weight: 600;
    font-size: 18px;
    color: var(--content-primary);
  }
}
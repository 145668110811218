.card {
  &-variant {
    &-blue {
      background: radial-gradient(63.64% 76.58% at 104.09% 112.11%, rgba(1, 186, 239, 0.1) 0%, rgba(1, 186, 239, 0.0553649) 37.25%, rgba(1, 186, 239, 0) 100%), #FFFFFF;
    }

    &-green {
      background: radial-gradient(63.64% 76.58% at 104.09% 112.11%, rgba(50, 188, 108, 0.1) 0%, rgba(50, 188, 108, 0.0553649) 37.25%, rgba(50, 188, 108, 0) 100%), #FFFFFF;
    }

    &-yellow {
      background: radial-gradient(63.64% 76.58% at 104.09% 112.11%, rgba(255, 212, 100, 0.1) 0%, rgba(255, 212, 100, 0.0553649) 37.25%, rgba(255, 212, 100, 0) 100%), #FFFFFF;
    }

    &-red {
      background: radial-gradient(63.64% 76.58% at 104.09% 112.11%, rgba(232, 0, 0, 0.1) 0%, rgba(232, 0, 0, 0.0553649) 37.25%, rgba(232, 0, 0, 0) 100%), #FFFFFF;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 18px;
}

.name {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 12, 116, 0.6);
}

.count {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000C74;
}

.line {
  background-color: var(--background-danger);
  border-radius: var(--radius-4);
}

.percentage {
  width: var(--percentage);
  height: 6px;
  background-color: var(--background-blue);
  border-radius: var(--radius-4);
}
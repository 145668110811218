.wrapper {
  display: flex;
  column-gap: 24px;
  align-items: flex-start;
  padding: 16px 24px;
  border-radius: var(--radius-10);
  border: 1px solid rgba(110, 91, 255, 0.30);
}

.index {
  font-weight: 600;
  font-size: 14px;
  color: #6E5BFF;
}

.name {
  margin-right: auto;
  font-size: 16px;
  color: #000C74;
}

.link {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.text {
  font-size: 16px;
  color: rgba(0, 12, 116, 0.40);
}

.icon {
  color: var(--content-blue);
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    opacity: .5;
  }
}
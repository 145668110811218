.wrapper {
  display: flex;
  flex-direction: column;

  &-align {
    &-center {
      align-items: center;
    }

    &-left {
      align-items: flex-start;
    }
  }

  &-variant {
    &-blue {
      .title {
        color: #000C74;
      }

      .line {
        background-color: #8991F7;
      }
    }

    &-white {
      .title {
        color: var(--content-white);
      }

      .line {
        background-color: var(--background-white);
      }
    }
  }
}

.title {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.line {
  width: 60px;
  height: 2px;
  border-radius: var(--radius-4);
}
.tab {
  display: flex;
  column-gap: 10px;
  align-items: center;

  &Name {
    font-size: 14px;
    line-height: 24px;
    color: #000C74;
  }

  &Count {
    padding: 2px 8px;
    font-weight: 500;
    font-size: 12px;
    background-color: #439FF4;
    color: #FFF;
    border-radius: var(--radius-20);
  }
}
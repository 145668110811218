.wrapper {
  padding: 8px;
  background-color: #EAE7FF;
  box-shadow: 0 4px 16px rgba(4, 22, 116, .26);
  border-radius: var(--radius-10);
}

.head {
  display: flex;
  column-gap: 24px;
  padding: 20px 16px;

  &Left {
    display: grid;
    place-items: center;
    width: 60px;
    height: 60px;
    color: var(--content-blue);
    background-color: rgba(110, 91, 255, .1);
    border-radius: var(--radius-10);
  }

  &Center {
    margin-right: auto;

    &Title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 20px;
      color: #4D35FF;
    }

    &Statuses {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }

    &Status {
      padding: 6px 8px;
      background-color: #32BC6C;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      color: var(--content-white);
      border-radius: var(--radius-8);
      border: 1px solid #32BC6C;

      &:last-child {
        background-color: transparent;
        color: var(--content-blue);
        border-color: var(--border-blue);
      }
    }
  }

  &Right {
    &Title {
      margin-bottom: 8px;
      font-size: 16px;
      color: rgba(0, 12, 116, .6);
    }

    &List {
      display: flex;
      justify-content: flex-end;
      column-gap: 8px;
    }

    &Item {
      padding: 6px 8px;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 12, 116, .6);
      border-radius: 28px;
      border: 1px solid rgba(0, 12, 116, .6);
    }
  }
}

.body {
  padding: 20px 16px;
  background-color: var(--background-white);
  border-radius: var(--radius-10);

  &Title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    color: #010D74;
  }

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
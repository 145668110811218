.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  background-color: var(--background-white);
  border: 1px solid rgba(110, 91, 255, 0.15);
  box-shadow: 0 4px 16px rgba(4, 22, 116, 0.06);
  border-radius: var(--radius-10);
  cursor: pointer;
  transition: all .3s ease;

  .name {
    flex-grow: 1;
  }

  &:hover {
    background: #6E5BFF;
    border: 1px solid rgba(110, 91, 255, 0.15);
    box-shadow: 0 12px 26px rgba(110, 91, 255, 0.36);

    .name,
    .count {
      color: var(--content-white);
    }
  }
}

.image {
  margin-bottom: 16px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.name {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: rgba(0, 12, 116, 0.6);
}

.count {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  color: #000C74;
}

.suffix {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}
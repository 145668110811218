.footer {
  padding: 60px 0;
  background: linear-gradient(63.56deg, #6B50FF 48.13%, #A145FF 100%);
  color: var(--content-white);
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.title {
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.list {
  li {
    display: flex;
    align-items: center;
    column-gap: 10px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.info,
.link {
  font-size: 14px;
  line-height: 20px;
}

.link {
  color: var(--content-white);
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}

.networks {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.text {
  font-size: 12px;
  line-height: 14px;
  opacity: .4;
}

.network {
  position: relative;
  transition: opacity .3s ease;

  &:hover {
    .networkTitle {
      display: block;
    }
  }

  &Title {
    position: absolute;
    left: -50%;
    bottom: 180%;
    display: none;
    padding: 4px 6px;
    background-color: var(--background-white);
    color: var(--content-blue);
    font-size: 12px;
    border-radius: var(--radius-4);
  }
}
.wrapper {
  width: 100%;

  &--focused {
    .input {
      border-color: var(--border-blue);
    }
  }

  &--state {
    &-default {
      .input {
        //border-color: var(--border-blue);

        &:hover {
          //border-color: var(--border-primary);
        }
      }
    }

    &-success {
      .input {
        border-color: var(--border-primary);

        &:hover {
          border-color: var(--border-primary);
        }
      }
    }

    &-error {
      .input {
        border-color: var(--border-danger);
        box-shadow: 0 0 0 2px rgba(238, 149, 149, 0.1);


        &:hover {
          border-color: var(--border-danger);
        }
      }
    }
  }
}

.input {
  width: 100%;
}

.message {
  color: var(--content-danger);
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 220px;
  padding: 32px 20px;
  background-color: var(--background-white);
  border: 1px solid rgba(0, 12, 116, .08);
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background-color: var(--background-blue);

    .icon svg path {
      stroke: var(--content-white);
    }

    .name,
    .code,
    .more {
      color: var(--content-white);
    }
  }
}

.icon {
  margin-bottom: 24px;
}

.info {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;
}

.name {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 12, 116, .87);
}

.code {
  font-weight: 600;
  font-size: 20px;
  line-height: 14px;
  color: #000C74;
}

.more {
  display: flex;
  column-gap: 12px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 12, 116, .6);
}